import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import Api from "../../../libraries/api";
import {toast, ToastContainer} from "react-toastify";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TicketLists from '../../presentational/ticket-list';
import TransactionList from '../../presentational/transaction-list';
import moment from "moment";
import _ from "lodash";

require('dotenv').config();

toast.configure({
    autoClose: 2000,
    draggable: false,
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


class AccountDetail extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingZipcode: false,
            errors: {},
            search: '',

            showDetail: false,
            showForm: false,
            showFormEdit: false,
            showPassword: false,
            showEdit:false,

            value: 0,
            rows: [],
            rowsActivity: [],

            id: '',
            name: '',
            accountNumber: '',
            contact: [],
            address: '',
            loginType: '',
            loginSince: '',
            accountCreated: '',

            payments: [],
            vehicles: [],
            transactions: [],
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.__fetchData(false);


    }

    __fetchData = update => {

        let id = this.props.match.params.id;

        let route = '/accounts/'+id;
        let route_payment = '/accounts/' + id + '/payments';
        let route_vehicle = '/accounts/' + id + '/vehicles';
        let route_activity = '/accounts/' + id + '/activity';

        Api.get(route).then(resp => {

            if (resp.data) {

                let data = resp.data.users;

                this.setState({
                    id: data.id,
                    name: data.name,
                    accountNumber: data.accountNumber,
                    tollTag: data.tollTag,
                    contact: data.contact,
                    address: data.address,
                    loginType: data.loginType,
                    loginSince: data.loginSince,
                    accountCreated: data.accountCreated,
                })
            }

        }).catch(err => {
            console.log(err);
        });


        Api.get(route_payment).then(resp => {
            if (resp.data) {
                this.setState({
                    payments: resp.data.payments
                })
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get(route_vehicle).then(resp => {
            if (resp.data) {
                this.setState({
                    vehicles: resp.data.vehicles
                })
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get(route_activity).then(resp => {
            if (resp.data.activity) {

                let act = _.orderBy(resp.data.activity, (o) => {
                    return moment(o.Created);
                }, ['desc']);

                this.setState({
                    loading: false,
                    rowsActivity: act,
                })
            }

        }).catch(err => {
            console.log(err);
        });

    };

    handleChange (e, newValue){

        this.setState({

            value: newValue

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }

    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };


    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <AppBar position="static" color="default">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2>{this.state.name}</h2>
                            </div>
                            <div className="col-md-3">
                                <p className="mb-0">Flagged: description</p>
                            </div>
                            <div className="col-md-3 text-right">
                                <Link to="/accounts" className="btn btn-blue-trans" >Back to list</Link>

                            </div>
                        </div>

                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Profile" {...a11yProps(0)} />
                            <Tab label="Vehicle" {...a11yProps(1)} />
                            <Tab label="Payment" {...a11yProps(2)} />
                            <Tab label="Settings" {...a11yProps(3)} />
                            <Tab label="Tickets" {...a11yProps(4)} />
                            <Tab label="Transactions" {...a11yProps(5)} />
                            <Tab label="Activity" {...a11yProps(6)} />
                        </Tabs>
                    </AppBar>

                    <TabPanel value={this.state.value} index={0}>
                        <div className="row">
                            <div className="col-md-4">
                                <label className="title">Name</label>
                                <p className="pl-2 pl-lg-3">
                                    {this.state.name}
                                </p>

                                <label className="title">Account number</label>
                                <p className="pl-2 pl-lg-3">
                                    {this.state.accountNumber}
                                </p>

                                <label className="title">Toll Tag ID</label>
                                <p className="pl-2 pl-lg-3">
                                    {this.state.tollTag}
                                </p>

                                <label className="title">Contact</label>
                                <div className="pl-2 pl-lg-3 mb-3">
                                    { this.state.contact.map((item, index) => {
                                        return (<p key={index} className="pl-2 pl-lg-3 mb-0">{item}</p>)
                                    })
                                    }
                                </div>

                                <button className="btn btn-blue-trans">Add contact</button>
                            </div>
                            <div className="col-md-4">
                                <label className="title">Address 1</label>
                                <p className="pl-2 pl-lg-3">
                                    {this.state.address}
                                </p>
                                <button className="btn btn-blue-trans">Add address</button>

                            </div>
                            <div className="col-md-4">
                                <label className="title">Flagged status</label>
                                <p className="pl-2 pl-lg-3">
                                    description in more detail
                                </p>
                                <button className="btn btn-blue-trans mb-3">Clear flag</button>

                                <label className="title">Login type</label>
                                <p className="pl-2 pl-lg-3">
                                    {this.state.loginType}
                                </p>

                                <label className="title">Logged in since</label>
                                <p className="pl-2 pl-lg-3">
                                    {this.state.loginSince}
                                </p>

                                <label className="title">Account created</label>
                                <p className="pl-2 pl-lg-3">
                                    {this.state.accountCreated}
                                </p>
                                <button className="btn btn-blue-trans mb-2">Force Logout</button>
                                <br />
                                <button className="btn btn-blue-trans mb-2">Resend OTP</button>

                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        <div className="row">
                            {
                                this.state.vehicles.length ? (
                                    this.state.vehicles.map((item, index) => {
                                        return (
                                            <div className="col-md-4" key={index}>
                                                <label className="title">Vehicle {index + 1}</label>
                                                <p className="pl-2 pl-lg-3">
                                                    {item.licensePlateNumber} <br/>
                                                    {item.licensePlateState} <br/>
                                                    {item.nickName}
                                                </p>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="col-md-4">
                                        <label className="title">No Vehicle</label>
                                    </div>
                                )
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={2}>
                        <div className="row">
                            {
                                this.state.payments.length ? (
                                    <>
                                    {
                                        this.state.payments.map((item, index) => {
                                            return (
                                                <div className="col-md-4" key={index}>
                                                    <label
                                                        className="title">{item.paymentType ? item.paymentType : item.paymentMethodName}</label>
                                                    <p className="pl-2 pl-lg-3">
                                                        {item.card === '' ? '' : 'Card Number ' + item.card} <br/>
                                                        {item.expires === '' ? '' : 'Expires ' + item.expires} <br/>
                                                        {
                                                            item.billingAddress &&
                                                            <>
                                                                <br/>
                                                                <strong>Billing Address:</strong><br/>
                                                                <span
                                                                    dangerouslySetInnerHTML={{__html: item.billingAddress}}/>
                                                            </>
                                                        }
                                                    </p>
                                                    <p className="pl-2 pl-lg-3 text-blue">
                                                        {item.primary === 1 ? '• primary' : ''}
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }
                                        <div className="col-12">
                                            <button className="btn btn-blue-trans mb-2">Add payment method</button>
                                        </div>
                                    </>

                                ) : (
                                    <div className="col-md-4">
                                        <label className="title">No Payment</label>
                                    </div>
                                )
                            }

                            {/*<div className="col-md-4">*/}
                                {/*<label className="title">Apple Pay</label>*/}
                                {/*<p className="pl-2 pl-lg-3">*/}
                                {/*    Comment about*/}
                                {/*</p>*/}
                                {/*<label className="title">Mastercard</label>*/}
                                {/*<p className="pl-2 pl-lg-3">*/}
                                {/*    5234 4568 1234 5678<br />*/}
                                {/*    Expires<br />*/}
                                {/*    Security code<br />*/}

                                {/*    Billing address<br />*/}
                                {/*    1060 W. Addison<br />*/}
                                {/*    Chicago, IL 60613*/}
                                {/*</p>*/}
                                {/*<button className="btn btn-blue-trans mb-2">Add payment method</button>*/}

                            {/*</div>*/}
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={3}>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="title">Permissions </label>

                                <div className="row">
                                    <div className="col-4">
                                        <p className="pl-2 pl-lg-3">
                                            Comment about
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p>Denied</p>
                                        <p>Granted</p>
                                    </div>
                                    <div className="col-4 pl-0">
                                        <button className="btn btn-blue-trans mb-2">Send request</button>
                                        <button className="btn btn-blue-trans mb-2">Send request</button>
                                    </div>
                                </div>
                                <label className="title">Diagnostics</label>
                                <p className="pl-2 pl-lg-3">
                                    App version
                                    Device
                                    etc.
                                </p>


                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={4}>
                        <div className="row">
                            <div className="col-12">
                                <label className="title">Tickets </label>
                            </div>
                        </div>
                        <TicketLists user={this.props.match.params.id}  />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={5}>
                        <div className="row">
                            <div className="col-12">
                                <label className="title">Transactions </label>
                            </div>
                        </div>
                        <TransactionList user={this.props.match.params.id}  />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={6}>
                        <div className="row">
                            <div className="col-12">
                                <label className="title">Account activity </label>

                                <div className="table-wrapper">
                                    <Table className="table-list mt-3" size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Event ID</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Created</TableCell>
                                                <TableCell>Event Description</TableCell>
                                                <TableCell>By</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={8} align="center" className="py-5">
                                                        <CircularProgress color="primary"  />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.rowsActivity.length === 0 ? (
                                                    <TableRow style={{ height: 33 * 10}}>
                                                        <TableCell colSpan={8} style={{ textAlign: "center" }}>No activity</TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.rowsActivity.map((row, index) => {

                                                        return (
                                                            <TableRow
                                                                key={index}
                                                                selected={row.selected}
                                                            >
                                                                <TableCell>{row.EventId === null ? 'NA' : row.EventId}</TableCell>
                                                                <TableCell>{row.Status === null ? 'NA' : row.Status}</TableCell>
                                                                <TableCell>{row.Created === null ? 'NA' : moment(row.Created).format('dddd, MMM D YYYY, k:mm:ss')}</TableCell>
                                                                <TableCell>{row.EventDescription === null ? 'NA' : row.EventDescription}</TableCell>
                                                                <TableCell>{row.by === null ? 'NA' : row.by}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                )
                                            )}

                                        </TableBody>
                                    </Table>
                                </div>

                            </div>
                        </div>
                    </TabPanel>
                </div>

                <ToastContainer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetail);