import React, {Component} from 'react';
import AuthHelper from "../../../libraries/auth-helper";
import AccessManagement from "../access-management";
import ContentDashboard from "../content-dashboard";
import NotFound from "../not-found";
import {Switch} from 'react-router-dom'
import Sidebar from "../sidebar";
import Navbar from "../navbar";
import Accounts from "../accounts";
import AccountDetail from "../accounts/detail";
import Info from "../info";
import Settings from "../settings";
import Tickets from "../tickets";
import Transactions from "../transactions";
import {PrivateRoute} from '../../../libraries/route-handling'
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";

const styleSidebar = {
    zIndex: '10',
    position: 'relative',
    maxWidth: '142px',
};

class Dashboard extends Component {

    componentDidMount() {

        if (AuthHelper.isLoggedIn()) {

            let profile = AuthHelper.getProfile();

            let user = JSON.parse(JSON.stringify(profile));
            delete user.role;

            let role = JSON.parse(JSON.stringify(profile.role));
            delete role.permisssions;

            let permissions = JSON.parse(JSON.stringify(profile.role.permissions));

            this.props.setProfile(user);
            this.props.setRole(role);
            this.props.setPermission(permissions);

        } else {

            AuthHelper.logOut();

            this.props.history.push('/login');

        }

    }

    render() {
        return (

            <div>

                <Navbar/>

                <main className="dashboard">

                    <div className="container-fluid">

                        <div className="row">

                            <div className="col-md px-0" style={styleSidebar}>

                                <Sidebar/>

                            </div>

                            <div className="col-md dash-content">

                                <Switch>
                                    <PrivateRoute exact path="/" component={ContentDashboard} />
                                    <PrivateRoute exact path="/accounts" component={Accounts} />
                                    <PrivateRoute path="/accounts/detail/:id" component={AccountDetail} />
                                    <PrivateRoute path="/tickets" component={Tickets} />
                                    <PrivateRoute path="/transactions" component={Transactions} />
                                    <PrivateRoute path="/access-management" component={AccessManagement} />
                                    <PrivateRoute path="/settings" component={Settings} />
                                    <PrivateRoute path="/info" component={Info} />
                                    <PrivateRoute component={NotFound} />
                                </Switch>

                            </div>

                        </div>

                    </div>

                </main>

            </div>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

